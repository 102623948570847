import "../styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useEffect, useState } from "react";
import { Router } from "next/router";
import Script from "next/script";
import dynamic from "next/dynamic";

const AppDotsLoading = dynamic(() => import("../components/AppDotsLoading"), {
  loading: () => <p>Loading...</p>,
});
const AppPopup = dynamic(() => import("../components/AppPopup"), {
  loading: () => <p>Loading...</p>,
});

export default function App({ Component, pageProps }: AppProps) {
  const [loading, setLoading] = useState(false);
  const [isStaging, setIsStaging] = useState(false);
  useEffect(() => {
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    Router.events.on("routeChangeStart", handleStart);
    Router.events.on("routeChangeComplete", handleComplete);
    Router.events.on("routeChangeError", handleComplete);

    return () => {
      Router.events.off("routeChangeStart", handleStart);
      Router.events.off("routeChangeComplete", handleComplete);
      Router.events.off("routeChangeError", handleComplete);
    };
  }, []);

  useEffect(() => {
    setIsStaging(window.location.href.includes("stg"));
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        {isStaging && <meta name="robots" content="noindex, nofollow" />}
      </Head>

      {loading && <AppDotsLoading />}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-QWJV61XSWH"
      />
      <AppPopup />
        <Component {...pageProps} />
    </>
  );
}
